//variables

/*Bootstrap 5 style changes pertinent to Ridge specifically*/
/*  
    This page and other changes not noted in GIT DIFFs between our code and the official BootStrap project at the 5.1.3 Release version. 
    Copyright 2022 University of Wisconsin System Board of Regents All Rights Reserved World.
 */

//a00000, the default UW color
$primary: #a00000;
$secondary: #d30000;
$success: #77b300;
$info: #2a9fd6;
$warning: #f80;
$danger: #ff06e6;

//Custom dark colors
$primary-dark: rgba(145, 0, 0, 1.0);
$secondary-dark: rgba(244, 134, 134, 1.0);
$success-dark: rgba(101, 153, 0, 1.0);
$info-dark: rgba(23, 115, 158, 1.0);
$warning-dark: rgba(219, 116, 0, 1.0);
$danger-dark: rgba(204, 4, 183, 1.0);

//fonts used in University Ridge
$font-family-uw: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-base: $font-family-uw;

$headings-color: $primary !default;

//scaled down font for University Ridge 
$font-size-base: 1rem*.875;

//Uridge's body color 
$body-color: #282828;
$line-height-base: 1.4;

$nav-pills-link-active-bg: #6f0000;
/*End of University Ridge specific style changes. The rest of them
are for the purposes of matching UW's Bootstrap 3 & 4 appearances */

//color for light navbar
$navbar-light-active-color: $primary;
$navbar-light-hover-color: $primary;
$nav-tabs-link-active-bg: $primary;
$nav-tabs-link-active-color: white;

//dropdown variables
$dropdown-bg: $primary;
$dropdown-link-color: white;
$dropdown-link-hover-color: white;
$dropdown-link-hover-bg: darken(rgb(162,0,0), 10%);
$dropdown-divider-bg: #870000;
$dropdown-header-color: white;

// Color Class Definitions

// Primary
.wa-primary {
  background-color: $primary !important;
}

.wa-primary-dark {
  background-color: $primary !important;
}

// Secondary
.wa-secondary {
  background-color: $secondary !important;
}

.wa-secondary-dark {
  background-color: $secondary !important;
}

// Success
.wa-success {
  background-color: $success !important;
}

.wa-success-dark {
  background-color: $success !important;
}

// Info
.wa-info {
  background-color: $info !important;
}

.wa-info-dark {
  background-color: $info !important;
}

// Warning
.wa-warning {
  background-color: $warning !important;
}

.wa-warning-dark {
  background-color: $warning !important;
}

// Danger
.wa-danger {
  background-color: $danger !important;
}

.wa-danger-dark {
  background-color: $danger !important;
}

////////////FONT COLORS////////////////

.wa-font-primary {
  color: $primary !important;
}

.wa-font-primary-dark {
  color: $primary !important;
}

.wa-font-secondary {
  color: $secondary !important;
}

.wa-font-secondary-dark {
  color: $secondary !important;
}

.wa-font-success {
  color: $success !important;
}

.wa-font-success-dark {
  color: $success !important;
}

.wa-font-info {
  color: $info !important;
}

.wa-font-info-dark {
  color: $info !important;
}

.wa-font-warning {
  color: $warning !important;
}

.wa-font-warning-dark {
  color: $warning !important;
}

.wa-font-danger {
  color: $danger !important;
}

.wa-font-danger-dark {
  color: $danger !important;
}



////////// BUTTONS \\\\\\\\\\

.wa-btn-primary {
  background-color: $primary !important;
}

.wa-btn-primary:hover {
  background-color: $primary-dark !important;
  font-weight: bold !important;
}

.onboardingButton {
  background-color: $primary;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  cursor: pointer;
  color: white;
}

.onboardingButton:hover {
  background-color: $primary-dark;
  font-weight: bold;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

////////// DROPDOWNS \\\\\\\\\\
.wa-dropdown-primary button {
  background-color: $primary-dark;
  height: 3rem;
}

.wa-dropdown-primary button:hover {
  background-color: $primary-dark;
  font-weight: bold !important;
}

.wa-dropdown-primary .dropdown-menu {
  min-width: 100%;
  max-height: 250px;
  overflow-y: scroll;
}

.wa-dropdown-primary .dropdown-item {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.wa-dropdown-primary .dropdown-menu .dropdown-item:active, .wa-dropdown-primary .dropdown-menu .dropdown-item:hover {
  background-color: #eeeeee !important;
}

.wa-dropdown-primary .selectedItem {
  background-color: #eeeeee !important;
}

.wa-dropdown-primary button.incomplete {
  background-color: $danger;
}

//list groups
.list-group-item-action {
    h1, h2, h3, h4, h5, h6{
        color: white !important;
    }
}


//cards
.card-title {
    color: white;
}

.card-footer {
    color: white;
}

.navbar-light{
    border-color: #e6e6e6;
}

//Buttons
.btn-warning{
    color: #fff !important;
}
.btn-primary{
    color: #fff !important;
}

.badge-warning{
    color: white !important;
}
  
// @import "functions";
// @import "variables";
// @import "mixins";
